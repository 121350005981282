import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import WebsocketInstance from '../utils/websocket';
import { getConfig, SimpleSpinner, Spinner } from '../utils';

class HandleStripeResponse extends Component {
  state = {
    isTransactionSuccessful: false,
    loader: true,
    transactionDetails: null,
    isDemoConverted: false,
  };

  componentDidMount() {
    if (this.props.match.params && this.props.match.params.paymentStatus === 'true') {
      WebsocketInstance.sendMessage({
        _handler: 'set-payment-transaction',
        isReact: true,
        paymentDetails: this.props.match.params,
      });
    } else {
      this.setState({
        loader: false,
        isTransactionSuccessful: false,
      });
    }
  }

  componentWillReceiveProps(props) {
    if (props.paymentDetails && props.paymentDetails.success) {
      this.setState({
        loader: false,
        isTransactionSuccessful: true,
        transactionDetails: props.paymentDetails.data ? props.paymentDetails.data : null,
        isDemoConverted: props.paymentDetails.data && props.paymentDetails.data.isDemoConverted ? true : false,
      });
    } else {
      this.setState({
        loader: false,
        isTransactionSuccessful: false,
      });
    }
  }

  renderSuccessfulTransaction() {
    return (
      <div className="transaction-wrapper">
        <div className="transaction-container">
          <div>
            <h3>Transaction Successful</h3>
          </div>
          <div className="mt-3">
            {this.state.isDemoConverted ? (
              <h4>Thank you for purchasing tests - Your account has been activated</h4>
            ) : (
              <h4>Thank you for purchasing tests - the test credits have been added to your account.</h4>
            )}
          </div>
        </div>
        <div className="button-container">
          <Button
            className="transaction-button"
            color="secondary"
            onClick={() => (window.location.href = getConfig().REDIRECTION_URL)}
          >
            OK
          </Button>
        </div>
      </div>
    );
  }

  renderUnsuccessfulTransaction() {
    return (
      <div className="transaction-wrapper">
        <div className="transaction-container">
          <div>
            <h3>Transaction failed</h3>
          </div>
          <div className="mt-3">
            <h4>Please try again</h4>
          </div>
        </div>
        <div className="button-container">
          <Button className="transaction-button" color="secondary" onClick={() => this.props.history.push('/plans')}>
            OK
          </Button>
        </div>
      </div>
    );
  }

  renderTransaction() {
    if (this.state.isTransactionSuccessful) {
      return this.renderSuccessfulTransaction();
    } else {
      return this.renderUnsuccessfulTransaction();
    }
  }

  render() {
    return (
      <div>
        {this.state.loader ? (
          <div>
            <SimpleSpinner />
            <Spinner title="Please wait while your payment is processing" />
          </div>
        ) : (
          this.renderTransaction()
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paymentDetails: state.data['set-payment-transaction'],
  };
};

export default connect(mapStateToProps, null)(HandleStripeResponse);
