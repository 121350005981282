import React, { Component } from 'react';
import { imageConstants, getConfig } from '../utils';
import WebsocketInstance from '../utils/websocket';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class Header extends Component {
  state = {
    userDetails: null,
    isTestsOnly: null,
  };

  componentDidMount() {
    let interval = setInterval(() => {
      if (WebsocketInstance.isWebsocketConnected()) {
        clearInterval(interval);
        WebsocketInstance.sendMessage({ _handler: 'load-profile', operation: 'getCompanyUserDetails', isReact: true });
      }
    }, 200);
  }

  componentWillReceiveProps(props) {
    if (props.user_details) {
      this.setState({
        userDetails: props.user_details.data,
        isTestsOnly: props.user_details.data.company.testsOnly
      });
    }

  }

  renderUserDetails() {
    return (
      <div
        className=" header-userDetails-container row mr-2"
        onClick={() => (window.location = `${getConfig().REDIRECTION_ROOT_URL}/dashboard`)}
      >
        <div className="userDetailsContainer">
          <h4 className="userName">{this.state.userDetails.name}</h4>
          <h6 className="companyName">{this.state.userDetails.company.name}</h6>
        </div>
        {this.state.userDetails.avatar.search('/images') === 0 ? (
          <img src={imageConstants.avatar} alt="profile pic" className="profileImage" />
        ) : (
          <img src={this.state.userDetails.avatar} alt="profile pic" className="profileImage" />
        )}
      </div>
    );
  }

  renderLinks() {
    if (this.state.isTestsOnly) {
      return (
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            <li className="mr-3">
              <a className="nav-link header-links" href={getConfig().REDIRECTION_TEST_URL}>
                Tests
              </a>
            </li>
            <li className="mr-3">
              <a className="nav-link header-links" href={getConfig().REDIRECTION_SEND_TEST_URL}>
                Send Test
              </a>
            </li>
          </ul>
          <div className="form-inline my-2 my-lg-0">{this.state.userDetails ? this.renderUserDetails() : null}</div>
        </div>
      );
    } else if (this.state.isTestsOnly === false) {
      return (
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            <li className="mr-3">
              <a className="nav-link header-links" href={getConfig().REDIRECTION_MANAGE_JOBS_URL}>
                Jobs<span className="sr-only">(current)</span>
              </a>
            </li>
            <li className="mr-3">
              <a className="nav-link header-links" href={getConfig().REDIRECTION_TEST_URL}>
                Tests
              </a>
            </li>
            <li className="mr-3">
              <a className="nav-link header-links" href={getConfig().REDIRECTION_CANDIDATES_URL}>
                Candidates
              </a>
            </li>
            <li className="mr-3">
              <a className="nav-link header-links" href={getConfig().REDIRECTION_MESSAGES_URL}>
                Messages
              </a>
            </li>
          </ul>
          <div className="form-inline my-2 my-lg-0">{this.state.userDetails ? this.renderUserDetails() : null}</div>
        </div>
      );
    }
  }

  render = () => {
    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark"
        onClick={() => (window.location = `${getConfig().REDIRECTION_ROOT_URL}/dashboard`)}
      >
        <Link to="/plans">
          <img src={imageConstants.logo} alt="Logo" className="pull-xs-left main-logo aos-init aos-animate fsLogo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {this.renderLinks()}
      </nav>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    user_details: state.data['load-profile'],
  };
};

export default connect(mapStateToProps, null)(Header);
