export const types = {
  SET_PLAN_DETAILS: 'SET_PLAN_DETAILS',
  SET_RESPONSE: 'SET_RESPONSE',
  SET_SESSION: 'SET_SESSION',
};

export function setPlanDetails(planDetails) {
  return (dispatch) => {
    dispatch({ type: types.SET_PLAN_DETAILS, payload: planDetails });
  };
}

export function setAPIResponse(data) {
  return (dispatch) => {
    dispatch({ type: types.SET_RESPONSE, payload: data });
  };
}

export function setSession(data) {
  return (dispatch) => {
    dispatch({ type: types.SET_SESSION, payload: data });
  };
}
