import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setPlanDetails } from '../actions';

class Plan extends Component {
  state = {
    currencies: Object.keys(this.props.purchaseData.price),
    selectedCurrency: Object.keys(this.props.purchaseData.price)[0],
    price: this.props.purchaseData
      ? this.props.purchaseData.price[Object.keys(this.props.purchaseData.price)[0]]
      : null,
    discountPrice: this.props.purchaseData
      ? this.props.purchaseData.price[Object.keys(this.props.purchaseData.price)[0]]
      : null,
    credits: 1,
    name: this.props.purchaseData ? this.props.purchaseData.name : null,
    _id: this.props.purchaseData._id,
    limitless: this.props.purchaseData.limitless ? true : false,
    type: this.props.purchaseData.name ? this.props.purchaseData.name : null,
    recurring: this.props.purchaseData.recurring ? this.props.purchaseData.recurring : false,
    description: this.props.purchaseData.description ? this.props.purchaseData.description : null,
  };

  componentDidMount() {
    if (!this.props.planDetails) {
      this.props.setPlanDetails(this.state);
    }
  }

  async handleNumberOfTests(event) {
    let credits;
    if (event.target.className === 'increment') {
      credits = this.state.credits + 1;
    } else {
      if (this.state.credits > 1) {
        credits = this.state.credits - 1;
      }
    }
    await this.setState({ credits: credits });
    this.calculateNewPrice();
  }

  async setCurrency(currency) {
    await this.setState({ selectedCurrency: currency });
    this.calculateNewPrice();
  }

  calculateNewPrice() {
    let credits = this.state.credits;
    let currency = this.state.selectedCurrency;
    let price = this.props.purchaseData.price[currency] * credits;

    let discountPrice;
    if (credits >= 10 && credits <= 19) {
      discountPrice = price - (price / 100) * 15;
    } else if (credits >= 20) {
      discountPrice = price - (price / 100) * 25;
    } else {
      discountPrice = price;
    }

    this.setState({
      price: price,
      discountPrice: discountPrice,
    });

    this.props.setPlanDetails({
      ...this.props.purchaseData,
      type: this.props.purchaseData.name,
      credits: this.state.credits,
      price: price,
      selectedCurrency: currency,
    });
  }

  renderPurchaseTestTab = () => {
    return (
      <div>
        <div className="mb-3">
          <span>
            Get <b>15%</b> off on 10 or more tests
          </span>
          <br />
          <span>
            Get <b>25%</b> off on 20 or more tests
          </span>
        </div>
        <div className="wholeDefault">
          <div className="typeDefault">
            <p>Select Tests</p>
          </div>
          <div className="plan">
            <div className={this.state.discountPrice != this.state.price ? 'planHeaderOnDiscount' : 'planHeader'}>
              {this.state.discountPrice != this.state.price ? (
                <div className="discountedPrice">
                  {this.state.price}
                  <font className="planCurrency">{this.state.selectedCurrency}</font>
                </div>
              ) : (
                ''
              )}
              <div className="dropdown currencyContainer">
                {this.state.discountPrice}
                <button
                  className="btn btn-secondary dropdown-toggle currency-dropdown-button"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.state.selectedCurrency}
                </button>
                <div className="dropdown-menu dropdown-menu-block" aria-labelledby="dropdownMenuButton">
                  {this.state.currencies.map((currency, key) => {
                    return (
                      <a
                        className="dropdown-item"
                        key={key}
                        value={currency}
                        onClick={() => this.setCurrency(currency)}
                      >
                        {currency}
                        <img
                          className="currency-flag"
                          src={
                            currency === 'SEK' ? require('../assets/Images/sv.svg') : require('../assets/Images/eu.svg')
                          }
                        />
                      </a>
                    );
                  })}
                </div>
              </div>
              <div className="editablePlan">
                <div className="decrement" onClick={(event) => this.handleNumberOfTests(event)}>
                  -
                </div>
                <div className="numberOfTests">{this.state.credits}</div>
                <div className="increment" onClick={(event) => this.handleNumberOfTests(event)}>
                  +
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.renderPurchaseTestTab();
  }
}

export default connect(null, { setPlanDetails })(Plan);
