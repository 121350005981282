import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Toast extends Component {
  static successToast(message) {
    toast(message);
  }

  render() {
    return <ToastContainer />;
  }
}
