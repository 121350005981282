import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as appConstants from '../utils/appConstants';
import Plan from '../components/plan';
import OptionsTab from '../utils/optionsTab';
import { setPlanDetails, setSession } from '../actions';
import WebsocketInstance from '../utils/websocket';
import { checkSession, Spinner, SimpleSpinner } from '../utils';
import { Toast } from '../utils';
import { redirectToCheckout } from '../actions/stripeHandler';
class SubscriptionPlans extends Component {
  state = {
    plans: [],
    defaultPlanKey: null,
    activeTabName: appConstants.tabs.purchase,
    selectedPlanDetails: null,
    planDetails: [],
    isInitialPageLoad: false,
    purchasePlan: {},
    loading: false,
  };

  componentDidMount() {
    checkSession();
    let interval = setInterval(() => {
      if (WebsocketInstance.isWebsocketConnected()) {
        clearInterval(interval);
        WebsocketInstance.sendMessage({ _handler: 'load-plans', isReact: true });
      }
    }, 200);
  }

  componentWillReceiveProps = (props) => {
    if (props.create_payment && props.create_payment.data) {
      redirectToCheckout(props.create_payment.data);
    }
    if (props.load_plans && props.load_plans.success && props.load_plans.data) {
      props.load_plans.data.map((plan, index) => {
        return plan;
      });

      let recurringPlan = props.load_plans.data.filter((plan, index) => {
        if (plan.recurring) {
          return plan;
        } else return null;
      });
      let purchasePlan = props.load_plans.data.filter((plan, index) => {
        if (!plan.recurring) {
          return plan;
        } else return null;
      });
      this.setState({
        planDetails: recurringPlan,
        purchasePlan,
      });
    } else if (props.load_plans && !props.load_plans.success) {
      Toast.successToast(props.load_plans.error);
    }
  };

  purchase = (planDetails) => {
    if (this.state.purchasePlan[0] && this.state.purchasePlan[0].price) {
      this.setState({
        loading: true,
      });
      planDetails.pricePerTest = this.state.purchasePlan[0].price;
      let paymentDetails = {
        _handler: 'create-payment',
        credits: planDetails.credits ? planDetails.credits : 'Unlimited',
        planID: planDetails._id,
        planType: planDetails.name,
        recurring: planDetails.recurring,
        currency: planDetails.selectedCurrency,
        isReact: true,
      };
      WebsocketInstance.sendMessage(paymentDetails);
    }
  };

  handleTabs = (tab) => {
    this.setState({ activeTabName: tab });
  };

  selectedPlan = (planDetails) => {
    this.setState({ defaultPlanKey: planDetails._id, selectedPlanDetails: planDetails });
  };

  recurringPlans = () => {
    return (
      <div>
        <h4>To purchase a plan, please contact at</h4>
        <a style={{ color: '#295c88', fontWeight: 'bold' }} href="mailto:sales@futureskill.com">
          sales@futureskill.com
        </a>
      </div>
    );
  };

  purchaseTestPlans = () => {
    if (this.state.purchasePlan[0]) {
      return <Plan selectedPlan={this.selectedPlan} purchaseData={this.state.purchasePlan[0]} />;
    }
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div>
            <SimpleSpinner />
            <Spinner title="Please wait while your payment is processing" />
          </div>
        ) : null}
        <OptionsTab
          handleTabs={this.handleTabs}
          activeTab={this.state.activeTabName}
          options={Object.values(appConstants.tabs)}
        />
        <div id="container">
          {this.state.activeTabName === appConstants.tabs.recurring ? this.recurringPlans() : this.purchaseTestPlans()}
        </div>
        <div className="submit">
          {this.state.activeTabName === appConstants.tabs.recurring ? null : (
            <button className="buyButton" onClick={() => this.purchase(this.props.planDetails)}>
              Purchase
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    planDetails: state.data.planDetails,
    defaultPlanKey: state.data.defaultPlanKey,
    primus_response: state.data.primus_response,
    load_plans: state.data['load-plans'],
    create_payment: state.data['create-payment'],
  };
};

export default connect(mapStateToProps, { setPlanDetails, setSession })(SubscriptionPlans);
