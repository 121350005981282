import React from 'react';

export const Spinner = (props) => {
  return (
    <div className="overlay">
      <div className="overlay-content">
        <div className="spinner-border text-light" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      <div className="paymentProcessing">
        <span>{props.title}</span>
      </div>
      <div className="paymentProcessing">
        <span>Do not refresh / reload the page until processing is done...</span>
      </div>
    </div>
  );
};

export const SimpleSpinner = () => {
  return (
    <div className="overlayWhite">
      <div className="overlay-contentWhite">
        <div className="spinner-border text-dark" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <br />
      </div>
    </div>
  );
};
