import React, { Component } from 'react';
import * as appConstants from '../utils/appConstants';

export default class OptionsTab extends Component {
  changeTabs = (tab) => {
    this.props.handleTabs(tab);
  };

  render() {
    return (
      <div className="tabs">
        <div className="nav nav-pills">
          {this.props.options.map((option) => {
            return (
              <span className="nav-item" key={option}>
                <span
                  className={this.props.activeTab === option ? 'nav-link open' : 'nav-link'}
                  onClick={() => {
                    this.changeTabs(option);
                  }}
                >
                  {option === appConstants.tabs.purchase ? 'Purchase Tests' : 'Recurring'}
                </span>
              </span>
            );
          })}
        </div>
      </div>
    );
  }
}
