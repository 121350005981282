import { getConfig } from '../utils';

export async function redirectToCheckout(session) {
  const stripe = await initializeStripe();
  stripe.redirectToCheckout({
    sessionId: session.id,
  });
}

function initializeStripe() {
  return window.Stripe(getConfig().STRIPE_KEY);
}
