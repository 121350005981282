import React from 'react';
import { Route } from 'react-router-dom';
import App from './components/app';
import SubscriptionPlans from './screens/subscriptionPlans';
import LandingPage from './screens/landingPage';
import HandleStripeResponse from './screens/handleStripeResponse';

export default (
  <App>
    <Route path="/" component={LandingPage} />
    <Route path="/plans" exact component={SubscriptionPlans} />
    <Route
      path="/checkout/:transactionId/:sessionID/:paymentID/:paymentStatus"
      exact
      component={HandleStripeResponse}
    />
  </App>
);
