import { types } from '../actions';

export default function (state = {}, action) {
  switch (action.type) {
    case types.SET_PLAN_DETAILS:
      return { ...state, planDetails: action.payload };
    case types.SET_RESPONSE:
      if (action.payload._handler && !(action.payload._handler === 'ping-pong')) {
        let primusObject = {
          [action.payload._handler]: action.payload,
        };
        return { ...state, ...primusObject };
      } else {
        return { ...state, set_response: action.payload };
      }
    case types.SET_SESSION:
      return { ...state, sessionData: action.payload };
    default:
      return state;
  }
}
