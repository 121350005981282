import React, { Component } from 'react';
import WebsocketInstance from '../utils/websocket';
import { Toast } from '../utils';
import Header from '../components/header';

class App extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="app-container">
          <div className="app-wrapper">
            <Toast />
            <WebsocketInstance {...this.props} />
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default App;
